import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
function marquee() {
  return (
    <>
      <Container fluid className='bgBlack'>

        <Row>
          <Col>
            <div className="example1 ">
              <h6 className='marquee p-2 text-theme marqueefont fw-bolder'>Register for video analysis course contact : 8983972605, Our candidates got jobs with state associations.</h6>
            </div>
          </Col>
        </Row>
      </Container>

    </>

  );
}

export default marquee;